import { getDateLocales } from '~src/globals/i18n/bootstrapDatepickerLocales.ts'
import { jQuery } from '~src/globals/jquery.ts'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js'

const locale = getDateLocales()

jQuery.fn.datepicker.dates['std'] = {
  days: locale.locale_days,
  daysShort: locale.locale_daysShort,
  daysMin: locale.locale_daysMin,
  months: locale.locale_months,
  monthsShort: locale.locale_monthsShort,
  today: locale.locale_today,
  clear: locale.locale_clear,
  rtl: false,
}
